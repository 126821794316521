import { FC } from "react";

interface IProps {
    handler: () => void;}

const Card: FC<IProps> = ({handler}) => {
    return (
        <>
            <button onClick={handler}>clicsdfsdfsdfsdfsdfsdfsdfsdfsdfk</button>
        </>
    )
}

export default Card;
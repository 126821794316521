import * as React from 'react';
import Card from './Card.tsx';

const App = (props) => {
  return (
    <div className="App">
      <Card handler={() => console.log('click me')}>
          <p>фывфыфлывфдылвф</p>
      </Card>
    </div>
  );
}

export default App;
